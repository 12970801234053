import { Component } from '@angular/core';

@Component({
  selector: 'screen-error-404',
  templateUrl: './error_404.html',
  styleUrls: ['./error_404.less']
})
export class Error_404 {

  public constructor() {

  }

  /*
   * Getters & Setters 
   */

  //here
}
