import { Injectable } from '@angular/core';
import { Routes, RoutesRecognized } from '@angular/router';

@Injectable()
export class ConfigurationService {
    private brand: string;
    private campaign: string;
    private token: string;

    private routes: Routes;


    public constructor() {
        
    }

    public getUrl() {
        return [this.getBrand(), this.getCampaign(), this.getToken()];
    }

    /*
    * Getters & Setters
    */

    public getBrand(): string {
        return this.brand;
    }

    public setBrand(brand: string): void {
        this.brand = brand;
    }

    public getCampaign(): string {
        return this.campaign;
    }

    public setCampaign(campaign: string): void {
        this.campaign = campaign;
    }

    public getToken(): string {
        return this.token;
    }

    public setToken(token: string): void {
        this.token = token;
    }

    public getRoutes(): Routes {
        return this.routes;
    }

    public setRoutes(routes: Routes): void {
        this.routes = routes;
    }
}
