import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/app/environments/environment';
import { HttpService } from 'src/app/services/http.service';
import { StatusService } from 'src/app/services/status.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-rowcard',
  templateUrl: './rowcard.html',
  styleUrls: ['./rowcard.less'],
})
export class RowCard implements OnInit {
  private http: HttpService;
  private status: StatusService;

  @Input('data') private data: any;

  private farmerName: string;
  private farmerId: string;
  private farmerPhoto: string;
  private farmerRegDate: string;
  private farmerLocaton: string;
  private cherryCollection: number;
  private fairchainPrice: number;
  private totalPerKgRoastedCoffee: number;
  private jaaradata: Array<any>;

  private date: string;
  private state: string;
  private quantity: number;
  private price: number;
  private slips: string;
  private altFarmerPhoto: string;
  private backuptest: string;
  private missingSlipText: string;
  private httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkFkbWluIiwiaWF0IjoxNjIwMTE0NDUwfQ.SlNj5FQJfYQ6UBRa_hBzytDrsTYd8V62zibp6VHRn_8"
    })
  }

  public constructor(http: HttpService, status: StatusService, translate: TranslateService) {
    this.http = http;
    this.status = status;
    this.jaaradata = [];
    this.setFarmerPhoto('/assets/images/circularreport/farmer-default.png');
    this.altFarmerPhoto = '/assets/images/circularreport/farmer-default.png';
    this.missingSlipText = translate.instant('slip-is-coming');
  }

  public ngOnInit() {
    this.setData(this.data);

    /*
     * Setters of 'global card information'
     */
    this.setFarmerData();
    this.setFarmerRegDate(this.getData().regDate);

    /*
     * Setters of 'transaction history tab'
     */
    for (let i = 0; i < this.getData().AssetList.length; i++) {
      this.setDate(this.getData().AssetList[i].submissionDate);
      this.setState(this.getData().AssetList[i].state);
      this.setQuantity(this.getData().AssetList[i].actualweight);
      this.setPrice(this.getData().AssetList[i].currentVal.price);

      //Mag veranderd worden naar de base64, deze zijn momenteel(6-8-2021) nog niet aangeleverd
      this.setSlips(this.getData().AssetList[i].document);
    }

    this.getData().submissionDates.forEach((value) => {
      //Moet beter kunnen, filter voor duplicates en push naar array en die inladen in html
      this.jaaradata.push(value.substring(0, 4));
    });

    /**
     * Get farmerimage
     */
    this.getHttp()
      .get(`${environment.URL_API}/provenance/v1/livefeed/farmerPicture`,{
        id: this.getFarmerId(),
      }, this.httpOptions)
      .subscribe((res: any) => {
        console.log(res);
        //if (
          //res != undefined &&
          //res != 'FILE NOT FOUND'
        //) {
          //this.setFarmerPhoto('data:image/jpeg;base64,' + res);
        //}
      });
  }

  /**
   * Sets farmer data
   */
  private setFarmerData() {
    this.setFarmerName(this.getData().farmerName);
    this.setFarmerId(this.getData().farmerID);
    this.setFarmerLocaton(this.getData().location);
    this.setCherryCollection(this.getData().totalQuantity.toFixed(2));
    this.setFairchainPrice(this.getData().totalFairPrice.toFixed(2));
    let fl = (this.getData().totalFairPrice.toFixed(2) / this.getData().totalQuantity.toFixed(2) * 7.86).toFixed(2);
    this.setTotalPerKgRoastedCoffee(Number(fl));//this.getData().marketpricePerKg.toFixed(2));
  }

  /**
   * Gets data from a specific year from a specific farmer
   */
  public getUserData(selectedValue, farmerId) {
    this.getStatus().setLoading(true);
    this.getHttp()
      .get(`${environment.URL_API}/provenance/v1/livefeed/farmerByYear`, {
        id: farmerId,
        year: selectedValue,
      },this.httpOptions)
      .subscribe((res: any) => {
        this.setData(res.data);
        this.setFarmerData();
        this.getStatus().setLoading(false);
      });
  }

  public setBackupImage(element: HTMLImageElement): void {
    element.src = '/assets/images/circularreport/notfound.png';
  }

  log(s) {
    console.log(s);
  }

  getModalId(data: any) {
    return `photoModal${data.transactionID.split('.').join('')}`
  }
  onSlipLoadError(data: any) {
    const modalId = this.getModalId(data);
    const slipModalOpenBtn = document.querySelector(`a[data-bs-target='#${modalId}']`)
    slipModalOpenBtn.textContent = this.missingSlipText;
    slipModalOpenBtn.removeAttribute("href");
    slipModalOpenBtn.removeAttribute("data-bs-target");
    slipModalOpenBtn.removeAttribute("data-bs-toggle");
  }
  /*
   * Getters & Setters
   */

  public getBackuptest(): string {
    return this.backuptest;
  }

  public setBackuptest(backuptest: string): void {
    this.backuptest = backuptest;
  }
  public getData(): any {
    return this.data;
  }

  public setData(data: any): void {
    this.data = data;
  }

  public getHttp(): HttpService {
    return this.http;
  }

  public setHttp(http: HttpService): void {
    this.http = http;
  }

  public getStatus(): StatusService {
    return this.status;
  }

  public setStatus(status: StatusService): void {
    this.status = status;
  }

  public getJaaradata(): Array<any> {
    return this.jaaradata;
  }

  public setJaaradata(jaaradata: Array<any>): void {
    this.jaaradata = jaaradata;
  }

  public getFarmerName(): string {
    return this.farmerName;
  }

  public setFarmerName(farmerName: string): void {
    this.farmerName = farmerName;
  }

  public getFarmerId(): string {
    return this.farmerId;
  }

  public getFarmerIdEscaped(): string {
    return this.farmerId.replace(/\./gi, "");
  }

  public setFarmerId(farmerId: string): void {
    this.farmerId = farmerId;
  }

  public setBackupFarmerImage(element: HTMLImageElement): void {
    element.src = this.getAltFarmerImage();
  }
  public getAltFarmerImage(): string {
    return this.altFarmerPhoto;
  }

  public getFarmerPhoto(): string {
    console.log(this.farmerId);
    return this.farmerPhoto;
  }

  public setFarmerPhoto(farmerPhoto: string): void {
    this.farmerPhoto = farmerPhoto;
  }

  public getFarmerRegDate(): string {
    return this.farmerRegDate;
  }

  public setFarmerRegDate(farmerRegDate: string): void {
    this.farmerRegDate = farmerRegDate;
  }

  public getFarmerLocaton(): string {
    return this.farmerLocaton;
  }

  public setFarmerLocaton(farmerLocaton: string): void {
    this.farmerLocaton = farmerLocaton;
  }

  public getCherryCollection(): number {
    return this.cherryCollection;
  }

  public setCherryCollection(cherryCollection: number): void {
    this.cherryCollection = cherryCollection;
  }

  public getFairchainPrice(): number {
    return this.fairchainPrice;
  }

  public setFairchainPrice(fairchainPrice: number): void {
    this.fairchainPrice = fairchainPrice;
  }

  public getTotalPerKgRoastedCoffee(): number {
    return this.totalPerKgRoastedCoffee;
  }

  public setTotalPerKgRoastedCoffee(totalPerKgRoastedCoffee: number): void {
    this.totalPerKgRoastedCoffee = totalPerKgRoastedCoffee;
  }

  public getDate(): string {
    return this.date;
  }

  public setDate(date: string): void {
    this.date = date;
  }

  public getState(): string {
    return this.state;
  }

  public setState(state: string): void {
    this.state = state;
  }

  public getQuantity(): number {
    return this.quantity;
  }

  public setQuantity(quantity: number): void {
    this.quantity = quantity;
  }

  public getPrice(): number {
    return this.price;
  }

  public setPrice(price: number): void {
    this.price = price;
  }

  public getSlips(): string {
    return this.slips;
  }

  public setSlips(slips: string): void {
    this.slips = slips;
  }
}
