import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-harvestcard',
  templateUrl: './harvestcard.html',
  styleUrls: ['./harvestcard.less'],
})
export class HarvestCard implements OnInit {
  @Input('harvestData') private data: any;

  private year: string;
  private totalCherry: number;
  private premiumprice: number;
  private currentYear: any;

  public constructor() {
    let currentTime = new Date();
    this.setCurrentYear(currentTime.getFullYear());
  }

  public ngOnInit() {
    console.log(this.data);
    this.setData(this.data);

    this.setYear(this.getData().year);// Object.keys(this.getData())[0]);
    this.setTotalCherry(this.getData().harvest.totalWeight.toFixed(2));
    this.setPremiumprice(this.getData().harvest.totalPremiumPrice.toFixed(2));
  }

  /*
   * Getters & Setters
   */
  public getData(): any {
    return this.data;
  }

  public setData(data: any): void {
    this.data = data;
  }
  public getYear(): string {
    return this.year;
  }

  public setYear(year: string): void {
    this.year = year;
  }

  public getTotalCherry(): number {
    return this.totalCherry;
  }

  public setTotalCherry(totalCherry: number): void {
    this.totalCherry = totalCherry;
  }

  public getPremiumprice(): number {
    return this.premiumprice;
  }

  public setPremiumprice(premiumprice: number): void {
    this.premiumprice = premiumprice;
  }

  public getCurrentYear(): any {
    return this.currentYear;
  }

  public setCurrentYear(currentYear: any): void {
    this.currentYear = currentYear;
  }
}
