<div class="row underline pt-3">
    <div class="col-md-12 pb-3 text-center text-md-start">
        <span class="title">{{ 'harvest-totals' | translate: {YEAR: getYear() } }}</span>
        <span *ngIf="getYear() == getCurrentYear()" class="title">{{ 'harvest-status-1' | translate }}</span>
        <span *ngIf="getYear() != getCurrentYear()" class="title">{{ 'harvest-status-2' | translate }}</span>
    </div>
    <div class="col-md-12 px-4 pb-2 text-center text-md-start">
        <span class="head">{{ 'harvest-totalCherry' | translate }}</span>
        <span>{{ 'harvest-totalCherry-KG' | translate: {KGCHERRY: getTotalCherry() } }}</span>
    </div>
    <div class="col-md-12 px-4 pb-2 text-center text-md-start">
        <span class="head">{{ 'harvest-totalPP' | translate }}</span>
        <span>{{ 'harvest-totalPP-birr' | translate: {PRICE: getPremiumprice() } }}
            <span *ngIf="getYear() == '2022'" class="d-inline" container="body" triggers="click:blur hover" tooltipClass="tooltip" ngbTooltip="{{ 'tooltip-4-2022' | translate}}"><i class="fas fa-info-circle"></i></span>
            <span *ngIf="getYear() == '2021'" class="d-inline" container="body" triggers="click:blur hover" tooltipClass="tooltip" ngbTooltip="{{ 'tooltip-4-2021' | translate}}"><i class="fas fa-info-circle"></i></span>
            <span *ngIf="getYear() == '2020'" class="d-inline" container="body" triggers="click:blur hover" tooltipClass="tooltip" ngbTooltip="{{ 'tooltip-4-2020' | translate}}"><i class="fas fa-info-circle"></i></span>
            <span *ngIf="getYear() == '2019'" class="d-inline" container="body" triggers="click:blur hover" tooltipClass="tooltip" ngbTooltip="{{ 'tooltip-4-2019' | translate}}"><i class="fas fa-info-circle"></i></span>
            <span *ngIf="getYear() == '2018'" class="d-inline" container="body" triggers="click:blur hover" tooltipClass="tooltip" ngbTooltip="{{ 'tooltip-4-2018' | translate}}"><i class="fas fa-info-circle"></i></span>
            <span *ngIf="getYear() == '2017'" class="d-inline" container="body" triggers="click:blur hover" tooltipClass="tooltip" ngbTooltip="{{ 'tooltip-4-2017' | translate}}"><i class="fas fa-info-circle"></i></span>
        </span>
    </div>
</div>
