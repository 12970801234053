import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class HttpService {
  public static readonly URL_API: string = environment.URL_API;

  private httpClient: HttpClient;
  private config: ConfigurationService;

  public constructor(httpClient: HttpClient, config: ConfigurationService) {
    if (!HttpService.URL_API)
      throw new Error('undefined API link in environment.');
    
    this.httpClient = httpClient;
    this.config = config;

    // console.log(HttpService.URI_API);
    // console.log(this.httpClient);
    // console.log(this.config);
  }

  public get(key: string, data: Object = {}, customHeaders = {}): Observable<Object> {
    /* Moet beter kunnen. (Object > queryString ) */
    let params: string[] = [];

    for (let key in data)
      params.push(new HttpParams().set(key, data[key]).toString());
    /**/

    return this.getHttpClient().request('GET', `${key}?${params.join('&')}`);
  }


  public post(
    url: string,
    data: Object,
    options: any = {}
  ): Observable<Object> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    });

    let http: Observable<Object>;

    /* Moet beter kunnen. (Object > queryString ) */
    let params: string[] = [];

    for (let key in data)
      params.push(new HttpParams().set(key, data[key]).toString());
    /**/

    const mergedOptions: Object = Object.assign(
      {
        withCredentials: true,
        headers: headers,
      },
      options
    );

    new Promise(() => {
      //resolve, reject
      http = this.getHttpClient()
        .post(url, params.join('&'), mergedOptions)
        .pipe(catchError((err: HttpErrorResponse) => this.errorHandler(err)));
      http.toPromise().then((res) => {
        //
      });
    });

    return http;
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error.message);
  }

  /*
   * Getters & Setters
   */

  public getHttpClient(): HttpClient {
    return this.httpClient;
  }

  public setHttpClient(httpClient: HttpClient): void {
    this.httpClient = httpClient;
  }

  public getConfig(): ConfigurationService {
    return this.config;
  }

  public setConfig(config: ConfigurationService): void {
    this.config = config;
  }
}
