<div class="container-fluid p-0">
    <div class="row">
        <div id="headContainer" class="col-md-12">
            <div class="row">
                <div class="col-md-12 head">
                    <span>{{ 'head-text-1' | translate }}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 content">
                    <span>{{ 'head-text-2' | translate }}</span>
                    <span>{{ 'head-text-3' | translate }}</span>
                    <span>{{ 'head-text-5' | translate }}</span>
                    <span>{{ 'head-text-4' | translate }}</span><a href="https://www.fairchain.org">our website</a>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div id="contentContainer" class="col-md-12">
            <div class="row">
                <div id="harvestContainer" class="col-12 col-md-3 col-xl-2 order-1 order-md-2">
                    <ng-container *ngFor="let i of getHarvestData(); let index = index;">
                        <app-harvestcard [harvestData]="i"></app-harvestcard>
                    </ng-container>
                </div>

                <div id="farmerContainer" class="col-12 col-md-9 col-xl-10 order-2 order-md-1" (scroll)="loadOnScroll($event)">
                    <ng-container *ngFor="let i of getShownData(); let index = index;">
                        <app-rowcard [data]="i"></app-rowcard>
                </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
