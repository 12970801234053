import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from 'src/app/environments/environment';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { HttpService } from 'src/app/services/http.service';
import { StatusService } from 'src/app/services/status.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-circularreport',
  templateUrl: './circularreport.component.html',
  styleUrls: ['./circularreport.component.less'],
})
export class CircularreportComponent implements OnInit {
  private http: HttpService;
  private status: StatusService;
  private config: ConfigurationService;

  // private data: Array<any>;
  private shownData: Array<any>;
  private harvestData: Array<any>;

  private year: number;
  private start: number;
  private end: number;
  private httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkFkbWluIiwiaWF0IjoxNjIwMTE0NDUwfQ.SlNj5FQJfYQ6UBRa_hBzytDrsTYd8V62zibp6VHRn_8"
    })
  }

  constructor(
    http: HttpService,
    config: ConfigurationService,
    status: StatusService
  ) {
    this.http = http;
    this.config = config;
    this.status = status;
    this.year = 2022;
    this.start = 0;
    this.end = 5;

    this.loadFarmers();

    /**
     * Load all harvest data
     */
    this.getHttp()
      .get(`${environment.URL_API}/provenance/v1/livefeed/harvestdata`,'',this.httpOptions)
      .subscribe((res: any) => {
        var outputArray = [];
        for (let element in res.data) {
            outputArray.push({
                year: res.data[element].year,
                harvest: res.data[element].harvest
            });
        }
        outputArray.sort((a, b) => a.year < b.year ? 1 : -1);
        this.setHarvestData(outputArray);
      });
  }

  log(s) {
    console.log(s);
  }

  ngOnInit(): void {}

  /**
   * Load farmers by 5
   */
  private loadFarmers() {
    this.getStatus().setLoading(true);
    this.getHttp()
      .get(`${environment.URL_API}/provenance/v1/livefeed/years`, {
        year: this.getYear(),
        start: this.getStart(),
        end: this.getEnd(),
      },this.httpOptions)
      .subscribe((res: any) => {
        if (this.getShownData() != undefined) {
          const sorted = this.getShownData().concat(res.data);
          this.setShownData(sorted);
        } else {
          this.setShownData(res.data);
        }
        this.getStatus().setLoading(false);
      });
  }

  /**
   * Function to load farmers on scroll event
   * @param event
   */
  @HostListener('scroll', ['$event'])
  public loadOnScroll(event: any): void {
    const loadAt: number = 100;
    event.preventDefault();
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      (event.target.scrollHeight / 100) * loadAt
    ) {
      this.setStart(this.getStart() + 5);
      this.setEnd(this.getEnd() + 5);
      this.loadFarmers();
    }
  }

  /*
   * Getters & Setters
   */

  public getHarvestData(): Array<any> {
    return this.harvestData;
  }

  public setHarvestData(harvestData: Array<any>): void {
    this.harvestData = harvestData;
  }

  public getShownData(): Array<any> {
    return this.shownData;
  }

  public setShownData(shownData: Array<any>): void {
    this.shownData = shownData;
  }

  public getHttp(): HttpService {
    return this.http;
  }

  public setHttp(http: HttpService): void {
    this.http = http;
  }

  public getConfig(): ConfigurationService {
    return this.config;
  }

  public setConfig(config: ConfigurationService): void {
    this.config = config;
  }

  public getStatus(): StatusService {
    return this.status;
  }

  public setStatus(status: StatusService): void {
    this.status = status;
  }

  public getYear(): number {
    return this.year;
  }

  public setYear(year: number): void {
    this.year = year;
  }

  public getStart(): number {
    return this.start;
  }

  public setStart(start: number): void {
    this.start = start;
  }

  public getEnd(): number {
    return this.end;
  }

  public setEnd(end: number): void {
    this.end = end;
  }
}
