import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.less']
})
export class LanguageSwitcherComponent {
  private languageService: LanguageService;

  public constructor(languageService: LanguageService) {
    this.languageService = languageService;
  }
  
  /*
   * Getters & Setters 
   */

  public getLanguageService(): LanguageService {
    return this.languageService;
  }

  public setLanguageService(languageService: LanguageService): void {
      this.languageService = languageService;
  }
}
