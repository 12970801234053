<div class="container-fluid" *ngIf="getStatus().isLoading()">
    <div class="row">
        <div id="loaderContainer" class="col-md center">
            <span id="loader">
                <span id="defaultLoader">
                    <loaders-css [loader]="'ball-pulse'" [scale]="1" [color]="'#685e12'"></loaders-css>
                </span>
            </span>
        </div>
    </div>
</div>