
<app-loader>
    <!-- <label>Custom loader hier!</label> -->    
</app-loader>

<div class="container-fluid">
    <div class="row">
        <div class="col-md">
            <app-language-switcher></app-language-switcher>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

