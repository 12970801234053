import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { NgxLoadersCssModule } from 'ngx-loaders-css';

import { Shell } from './components/shell/shell';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { RowCard } from './components/rowcard/rowcard';

import { HttpService } from './services/http.service';
import { ConfigurationService } from './services/configuration.service';
import { LanguageService } from './services/language.service';
import { CircularreportComponent } from './screens/circularreport/circularreport.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HarvestCard } from './components/harvestcard/harvestcard';
import { StatusService } from './services/status.service';
import { Loader } from './components/loader/loader';

@NgModule({
  declarations: [
    Shell,
    LanguageSwitcherComponent,
    CircularreportComponent,
    RowCard,
    HarvestCard,
    Loader
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxLoadersCssModule,
    NgbModule,
  ],
  providers: [
    HttpService,
    LanguageService,
    ConfigurationService,
    StatusService
  ],
  bootstrap: [Shell]
})
export class AppModule {

}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
