<div class="container-fluid x">
    <div class="row">
        <div id="navbar" class="col-md-12">
            <div class="dropdown">
                <span>
                    <a>
                    <img
                        class="language-switcher"
                        src="{{ 'header-language-switcher-flag-active' | translate }}"
                        alt=""
                    />
                    </a>
                    <i class="dropdown-icon fas fa-angle-down"></i>
                </span>
            
                <div class="dropdown-content">
                    <a class="dropdown-item" (click)="getLanguageService().switchLanguage(countryCode1.value)">
                        <img
                            src="{{ 'header-language-switcher-flag-not-active' | translate }}"
                            alt="Language selector"
                        />
                        <input
                            type="hidden"
                            value="{{
                            'header-language-switcher-flag-not-active-code' | translate
                            }}"
                            #countryCode1
                        />
                    </a>
            
                    <a class="dropdown-item" (click)="getLanguageService().switchLanguage(countryCode2.value)">
                        <img
                            src="{{ 'header-language-switcher-flag-not-active2' | translate }}"
                            alt="Language selector"
                        />
                        <input
                            type="hidden"
                            value="{{
                            'header-language-switcher-flag-not-active-code2' | translate
                            }}"
                            #countryCode2
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>