import { Component } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './shell.html',
  styleUrls: ['./shell.less']
})
export class Shell {
  private language: LanguageService;

  private httpService: HttpService;
  private userLang: string;
  private langs: any;

  public constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  /*
   * Getters * Setters 
   */

  public getHttpService(): HttpService {
    return this.httpService;
  }

  public setHttpService(httpService: HttpService): void {
      this.httpService = httpService;
  }

}
