import { NgModule } from '@angular/core';
import { ActivatedRoute, RouterModule, Routes } from '@angular/router';

import { ConfigurationService } from './services/configuration.service';

import { Error_404 } from './screens/global/error_404/error_404';
import { CircularreportComponent as circularreport } from './screens/circularreport/circularreport.component';

const routes: Routes = [

  /**
   * MoyeeCoffee
   */

   { path: '', component: circularreport },

   /**
    * Global
    */
   { path: '**', redirectTo: 'error-404' },
   { path: 'error-404', component: Error_404 },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  private config: ConfigurationService;

  public constructor(config: ConfigurationService, public route: ActivatedRoute) {
    this.config = config;
  }

  /*
   * Getters & Setters
   */

  public getConfig(): ConfigurationService {
    return this.config;
  }

  public setConfig(config: ConfigurationService): void {
      this.config = config;
  }
}
