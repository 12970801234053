import { Component } from '@angular/core';
import { StatusService } from 'src/app/services/status.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.html',
  styleUrls: ['./loader.less']
})
export class Loader {
  private status: StatusService;

  public constructor(status: StatusService) {
   this.status = status;
  }
  
  /*
   * Getters & Setters 
   */

  public getStatus(): StatusService {
    return this.status;
  }

  public setStatus(status: StatusService): void {
      this.status = status;
  }

}
