import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageService {
    private translate: TranslateService;

    private userLanguage: string;
    private languages: Array<string>;
    
    public constructor(translate: TranslateService) {
        this.translate = translate;

        const storedLanguage = localStorage.getItem('lang');

        this.setLanguages(navigator.language.split('-'));
    
        this.translate.addLangs(['en', 'nl', 'de']);
        this.translate.setDefaultLang(this.getUserLanguage() || 'nl');
    
        if (storedLanguage) {
          this.setUserLanguage(storedLanguage);
        } else {
          const newLanguage = this.getLanguages()[0];
    
          this.setUserLanguage(newLanguage);
          localStorage.setItem('lang', newLanguage);
        }
    }

    public switchLanguage(countryCode: string): void {
        // console.log(countryCode);
        // console.log(localStorage.getItem('lang'));
        this.getTranslateService().use(countryCode);
        localStorage.setItem('lang', countryCode);
    }

    /*
    * Getters & Setters
    */

    public getTranslateService(): TranslateService {
        return this.translate;
    }

    public setTranslateService(translateService: TranslateService): void {
        this.translate = translateService;
    }

    public getUserLanguage(): string {
        return this.userLanguage;
    }

    public setUserLanguage(lang: string): void {
        this.userLanguage = lang;
    }

    public getLanguages(): Array<string> {
        return this.languages;
    }

    public setLanguages(languages: Array<string>): void {
        this.languages = languages;
    }
}
