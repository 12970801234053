<div class="row">
    <div class="offset"></div>

    <div id="farmer-container" class="col-md">
        <div class="row">
            <div class="farmer col-md">
                <div class='card row'>
                    <div class="col-md-12">
                        <div id="farmerinfo" class="row">
                            <div class="col-sm-3 col-md-3 col-lg-2 text-center">
                                <span class="regDate">{{ 'farmer-regdate' | translate: {REG_DATE: getFarmerRegDate() } }}</span>
                            </div>
                            <div class="col-sm-7 col-md-7 col-lg-8 p-2 p-sm-0">
                                <span class="farmerName">{{ 'farmer-name' | translate: {FARMERNAME: getFarmerName() } }}<span container="body" triggers="click:blur hover" tooltipClass="tooltip" ngbTooltip="{{ 'tooltip-1' | translate }}"><i class="fas fa-info-circle"></i></span></span>
                            </div>
                            <div class="col-sm-2 col-md-2 col-lg-2 text-center text-md-start">
                                <form>
                                    <select #selectList class="form-control-sm" (change)="getUserData($event.target.value, getFarmerId())">
                                        <option *ngIf="getJaaradata().includes('2022')">2022</option>
                                        <option *ngIf="getJaaradata().includes('2021')">2021</option>
                                        <option *ngIf="getJaaradata().includes('2020')">2020</option>
                                        <option *ngIf="getJaaradata().includes('2019')">2019</option>
                                        <option *ngIf="getJaaradata().includes('2018')">2018</option>
                                        <option *ngIf="getJaaradata().includes('2017')">2017</option>
                                    </select>
                                </form>
                            </div>
                        </div>

                        <div id="farmerContent" class="row">
                            <div id="farmerimgContainer" class="col-md-2 align-self-center text-center pt-4 pt-sm-0">
                                <img #farmerimg [attr.src]="'https://firebasestorage.googleapis.com/v0/b/fairchain-images/o/farmers%2F'+ getFarmerId() + '.JPG?alt=media'"  (error)="setBackupFarmerImage(farmerimg)" class="img-fluid farmerImage" alt="farmer">
                            </div>

                            <div class="col-md-10">
                                <div id="tableContainer-1" class="row mt-2 mt-md-3">
                                    <div class="col-md-12 text-center text-md-start">
                                        <div class="row">
                                            <div class="col-12 col-md-4 col-lg-3">
                                                <span class="head">{{ 'farmer-cherrycollection' | translate: {YEAR: selectList.value } }}</span>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-9">
                                                <span>{{ 'farmer-cherrykg' | translate: {CHERRY: getCherryCollection() } }}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-12 text-center text-md-start">
                                        <div class="row">
                                            <div class="col-12 col-md-4 col-lg-3">
                                                <span class="head">{{ 'farmer-location' | translate }}</span>
                                            </div>
                                            <div class="col-12 col-md-8 col-lg-9">
                                                <span>{{ 'farmer-location-name' | translate: {LOCATION: getFarmerLocaton() } }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="tableContainer-2" class="row mt-2 mt-md-3">
                                    <div class="col-12 col-md-4 col-lg-3 order-1 order-md-1 text-center text-md-start">
                                        <span class="head">{{ 'farmer-fairchainPrice' | translate }} <span container="body" triggers="click:blur hover" tooltipClass="tooltip" ngbTooltip="{{ 'tooltip-2' | translate }}"><i class="fas fa-info-circle"></i></span></span>
                                    </div>

                                    <div class="col-12 col-md-4 col-lg-3 order-2 order-md-3 text-center text-md-start">
                                        <span>{{ 'farmer-fairchainPrice-Birr' | translate: {FAIRCHAINPRICE: getFairchainPrice() } }}</span>
                                    </div>

                                    <div class="col-12 col-md-8 col-lg-9 order-3 order-md-2 text-center text-md-start">
                                        <span class="head">{{ 'farmer-totalKgRoastedCoffee' | translate }}</span>
                                    </div>

                                    <div class="col-12 col-md-8 col-lg-9 order-4 order-md-4 text-center text-md-start">
                                        <span>{{ 'farmer-totalKgRoastedCoffee-Birr' | translate: {TOTALROASTED: getTotalPerKgRoastedCoffee() } }}<span container="body" triggers="click:blur hover" tooltipClass="tooltip" ngbTooltip="{{ 'tooltip-4-'+selectList.value | translate }}"><i class="fas fa-info-circle"></i></span></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 text-center text-md-start pt-3 pt-md-0" id="collapseContainer">
                                        <span>
                                            <a data-bs-toggle="collapse" [attr.href]="'#collapseTable' + getFarmerIdEscaped()" role="button" aria-expanded="false" aria-controls="collapseTable">{{ 'farmer-transactionHistory' | translate }}</a>
                                        </span>

                                        <div class="collapse" [attr.id]="'collapseTable' + getFarmerIdEscaped()">
                                            <div class="table-responsive">
                                                <table class="table table-borderless">
                                                    <thead>
                                                      <tr>
                                                        <th>{{ 'farmer-table-date' | translate }}</th>
                                                        <th>{{ 'farmer-table-state' | translate }}</th>
                                                        <th>{{ 'farmer-table-quanttity' | translate }}</th>
                                                        <th>{{ 'farmer-table-price' | translate }}</th>
                                                        <th>{{ 'farmer-table-slips' | translate }}</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr *ngFor="let i of getData().AssetList; let index = index;">
                                                        <td>{{i.submissionDate.split(' ')[0]}}</td>
                                                        <td>{{i.state}}</td>
                                                        <td>{{i.actualweight.toFixed(2)}}</td>
                                                        <td>{{i.currentVal.price.toFixed(2)}}</td>
                                                        <td>
                                                            <ng-container *ngIf="getSlips() != undefined">
                                                                <a href="#" data-bs-toggle="modal" [attr.data-bs-target]="'#'+getModalId(i)">
                                                                  {{ i.document ? i.transactionID : ('slip-is-coming' | translate) }}
                                                                </a>

                                                                <div class="modal fade" [attr.id]="getModalId(i)" role="dialog" tabindex="-1" aria-hidden="true" aria-labelledby="photoModal">
                                                                    <div class="modal-dialog modal-lg">
                                                                        <div class="modal-content">
                                                                            <div class="modal-header">
                                                                                <h5 class="modal-title">{{i.transactionID}}.jpg</h5>
                                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                            </div>
                                                                            <img [attr.src]="'https://firebasestorage.googleapis.com/v0/b/fairchain-images/o/submissions%2F'+ i.document + '?alt=media'" (error)="onSlipLoadError(i)" data-dismiss="modal" class="img-fluid">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
