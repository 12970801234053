import { Injectable } from '@angular/core';

interface Status {

}

@Injectable()
export class StatusService {
    private loading: boolean;

    public constructor() {
        this.loading = false;
    }

    /*
    * Getters & Setters
    */
    public isLoading(): boolean {
        return this.loading;
    }

    public setLoading(loading: boolean): void {
        this.loading = loading;
    }

}
